<template>
  <div class="logo">
    <img src="../assets/Logo-Light.jpg"/>
    <div>
      Coming Soon.
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.logo {
  margin: auto;
  width: 50%;
  height: 50%;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
